import React, { Component } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"

class Page extends Component {
  render() {
    const StaticPage = this.props.data.wordpressPage

    console.log(StaticPage)

    return (
      <Layout>
        <SEO title="Querida Sensatez" />
        <div className="querida">
          <div
            className="querida-intro"
            data-sal="fade-up"
            data-sal-delay="600"
            data-sal-easing="ease"
          >
            <h3 className="querida-intro__title">Querida Sensatez</h3>
            <h5 className="querida-intro__subtitleAlt">{StaticPage.title}</h5>
            <div
              dangerouslySetInnerHTML={{ __html: StaticPage.content }}
              className="querida-intro__paragraph"
            />

            <hr />
            <div className="podcast-story__footer">
              <div className="share">
                <p>Compartir:</p>
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=https://parteaguas.org/${StaticPage.slug}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook
                </a>
                <a
                  href={`http://www.twitter.com/intent/tweet?url=https://parteaguas.org/${StaticPage.slug}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Twitter
                </a>
              </div>
              <div>
                <p>{StaticPage.date}</p>
              </div>
            </div>
            <hr />
            <div className="podcast-story__footer podcast-story__footerAlt2">
              <a
                href={`/${StaticPage.acf.querida_anterior}`}
                className="podcast-story__previous"
              >
                <svg
                  width="11"
                  height="9"
                  viewBox="0 0 11 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.5"
                    d="M4.84704 0.0909085L5.61266 0.846591L2.46067 3.99858L10.624 3.99858L10.624 5.09233L2.46067 5.09233L5.61266 8.23437L4.84703 9L0.39249 4.54545L4.84704 0.0909085Z"
                    fill="#4D4B4B"
                  />
                </svg>
                <p>Querida Anterior</p>
              </a>
            </div>
          </div>
          <div
            className="querida-illustration"
            data-sal="fade-up"
            data-sal-delay="600"
            data-sal-easing="ease"
          >
            <img
              height="450"
              width="450"
              src={StaticPage.acf.imagen_portada.source_url}
              alt="Parteaguas"
              className="querida-illustration__img"
            />
          </div>
        </div>
      </Layout>
    )
  }
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default Page

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      slug
      title
      content
      date(formatString: "DD/MM/YYYY")
      acf {
        imagen_portada {
          source_url
        }
        querida_anterior
      }
    }
    site {
      id
      siteMetadata {
        title
        description
      }
    }
  }
`
